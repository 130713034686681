.leaflet-container {
  height: 95vh;
  width: "100%";
}

/* .cluster-icon {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    stroke: red;
    stroke-width: 5px;
    background-color: #fff;
    transform: translate(35%, 25%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 1.5rem;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
} */

.cluster-icon-error {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 6px solid #d62828;
  background-color: #fff;
  transform: translate(35%, 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.5rem;
  color: #d62828;
  font-family: Arial, Helvetica, sans-serif;
}
.cluster-icon-error {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 6px solid #d62828;
  background-color: #fff;
  transform: translate(35%, 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.5rem;
  color: #d62828;
  font-family: Arial, Helvetica, sans-serif;
}
.cluster-icon-warning {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 6px solid #ffd400;
  background-color: #fff;
  transform: translate(35%, 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.5rem;
  color: #ffd400;
  font-family: Arial, Helvetica, sans-serif;
}
.cluster-icon-ok {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 6px solid #4cb944;
  background-color: #fff;
  transform: translate(35%, 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.5rem;
  color: #4cb944;
  font-family: Arial, Helvetica, sans-serif;
}
.cluster-icon-unavailable {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 6px solid #666666;
  background-color: #fff;
  transform: translate(35%, 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 1.5rem;
  color: #666666;
  font-family: Arial, Helvetica, sans-serif;
}

@keyframes blinking {
  0% {
    background-color: rgba(0, 0, 0, 1);
  }

  50% {
    background-color: #29b6f6;
  }

  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}

.blinking {
  animation: blinking 1s infinite;
}

.leaflet-fade-anim .leaflet-popup {
  transition: none;
}
